import React from "react";
import "./index.css";
import FooterWidgets from "./Widgets";
import CopyRight from "./Copy-Right/index";

const Footer = () => (
  <footer>
    <div>
      <FooterWidgets />
      <CopyRight />
    </div>
  </footer>
);

export default Footer;
