import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Header from "../Header/index";
import Footer from "../Footer/index";

import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../static/fonts/Open-Sans/stylesheet.css";
import "../../../static/fonts/Raleway/stylesheet.css";
import "react-widgets/dist/css/react-widgets.css";
import "react-toastify/dist/ReactToastify.css";
import "./layout.css";
import RootServices from "../RootServices";
import { ToastContainer } from "react-toastify";

const Layout = ({ children, hideFooter, hideHeader }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  console.log("Layout initialized.........", data.site.siteMetadata);
  return (
    <React.Fragment>
      <RootServices>
        <main className="site-cont">
          {!hideHeader && <Header siteTitle={data.site.siteMetadata.title} />}
          <ToastContainer />
          {children}
          {!hideFooter && <Footer />}
        </main>
      </RootServices>
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
