import React from "react";
import { useSubscription, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { toast } from "react-toastify";
import { isBrowser } from "../../utils/general";

const waitForPaymentConfirm = gql`
  subscription($id: ID!) {
    awaitPayment(rideId: $id) {
      paymentStatus
      paymentType
      netFare
      rideId
    }
  }
`;

const rideDetail = gql`
  query($id: String!) {
    ride(id: $id) {
      status
    }
  }
`;

export default ({ children }) => {
  let rideId = isBrowser()
    ? sessionStorage.getItem("@paymentConfirmation")
    : null;

  if (rideId) {
    const { data: rideData, loading, error } = useQuery(rideDetail, {
      variables: {
        id: rideId,
      },
    });

    if (rideData) {
      console.log("rideData.ride.status: ", rideData.ride.status);
      if (rideData.ride.status !== "accepted") {
        let path = isBrowser() ? window.location.pathname : "";
        if (path === "/ride-accepted/" || path === "/payment/") {
          sessionStorage.removeItem("@paymentConfirmation");
          localStorage.removeItem("XXXX");
          alert("Something went wrong. Please book ride again.");
          window.location.href = "/";
          return;
        }
      }
    }

    console.log("rideData: ", rideData);

    const { data } = useSubscription(waitForPaymentConfirm, {
      variables: {
        id: rideId,
      },
    });

    console.log(
      "%c Awaiting payment....",
      "color: blue; font-size: 20px; background:yellow;"
    );
    console.log("waitForPaymentConfirm : ", data);
    if (data && data.awaitPayment && data.awaitPayment.rideId === rideId) {
      const { paymentStatus, paymentType } = data.awaitPayment;
      if (paymentStatus !== "paid" && paymentType !== "cash") {
        sessionStorage.removeItem("@paymentConfirmation");
        localStorage.removeItem("XXXX");
        toast.error("Payment Not Completed. Please Order Again", {
          autoClose: 1200,
        });
        window.location.href = "/";
      }
    }

    if (loading) return "Loading...";
  } else {
    let path = isBrowser() ? window.location.pathname : "";
    if (path === "/ride-accepted/" || path === "/payment/") {
      alert("Something went wrong. Please book ride again.");
      window.location.href = "/";
      return;
    }
  }

  return children;
};
