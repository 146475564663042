import React, { Component, useContext } from "react";
import { Link } from "gatsby";
import "./index.css";
import { LanguageContext } from "../../../lang";
import RoomIcon from "@material-ui/icons/Room";
import LanguageIcon from "@material-ui/icons/Language";
import CallIcon from "@material-ui/icons/Call";
import { useMediaQuery } from "react-responsive";

const FooterWidgets = () => {
  const {
    translations: { footer },
  } = useContext(LanguageContext);

  console.log("footer------------> ", footer);

  const {
    contactHeading,
    infoHeading,
    companyInfoHeading,
    address,
    supportEmail,
    telefon,
    infoLink1,
    infoLink2,
    infoLink3,
    infoLink4,
    infoLink5,
    companyName,
    kvk,
    companyAddress,
  } = footer;
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });
  // render() {
  return (
    <div
      className="row"
      style={{ padding: "0 20px 5px " + (isDesktopOrLaptop ? "100px" : "") }}
    >
      <div className="col-lg-4 col-md-12 widget-col mt-lg-0 mt-md-4">
        <h3 className="widget-head">{contactHeading}</h3>
        <ul className="widget-links">
          <li>
            <RoomIcon />
            <span>{address}</span>
          </li>
          <li>
            <LanguageIcon />
            <span>{supportEmail}</span>
          </li>
          <li>
            <CallIcon />
            <span>{telefon}</span>
          </li>
        </ul>
      </div>

      <div className="col-lg-4 col-md-12 widget-col mt-lg-0 mt-md-4">
        <h3 className="widget-head">{infoHeading}</h3>
        <ul className="widget-links">
          <li>
            <Link to="/" style={{ marginLeft: isDesktopOrLaptop ? 0 : 25 }}>
              {infoLink1}
            </Link>
          </li>
          <li>
            <Link
              to="/general-conditions"
              style={{ marginLeft: isDesktopOrLaptop ? 0 : 25 }}
            >
              {infoLink2}
            </Link>
          </li>
          <li>
            <Link
              to="/privacy-policy"
              style={{ marginLeft: isDesktopOrLaptop ? 0 : 25 }}
            >
              {infoLink3}
            </Link>
          </li>
          <li>
            <Link
              to="/contact-us"
              style={{ marginLeft: isDesktopOrLaptop ? 0 : 25 }}
            >
              {infoLink4}
            </Link>
          </li>
          <li>
            <Link to="/blog" style={{ marginLeft: isDesktopOrLaptop ? 0 : 25 }}>
              {infoLink5}
            </Link>
          </li>
        </ul>
      </div>

      <div className="col-lg-4 col-md-12 widget-col mt-lg-0 mt-md-4">
        <h3 className="widget-head">{companyInfoHeading}</h3>
        <ul className="widget-links">
          <li>
            <RoomIcon style={{ color: "#f1f1f1" }} />
            <span>{companyName}</span>
          </li>
          {kvk && (
            <li>
              <RoomIcon style={{ color: "#f1f1f1" }} />
              <span>{kvk}</span>
            </li>
          )}

          <li>
            <RoomIcon />
            <span>{companyAddress}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FooterWidgets;
