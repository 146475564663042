import React, { Component } from "react";
import "./index.css";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";

const Fab = ({ children, link }) => (
  <div
    style={{
      background: "#ed5523",
      padding: 11,
      borderRadius: 20,
      color: "white",
      height: 40,
      width: 40,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: 5,
    }}
  >
    <a style={{ color: "white", marginTop: 5 }} href={link}>
      {children}
    </a>
  </div>
);

class CopyRight extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row copyright-row">
          <div
            className="col-6 text-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="footer-copyright">
              © 2020 TAXIONSPOT. All copy right reserved
          </p>
          </div>
          <div
            className="col-6 text-center"
            style={{
              display: "flex",
              paddingBottom: 14,
              justifyContent: "center",
            }}
          >
            <Fab link="https://www.facebook.com/Taxionspot/">
              <i className="fa fa-facebook" aria-hidden="true" style={{}}></i>
            </Fab>
            <Fab link="https://twitter.com/taxionspot/">
              <TwitterIcon />
            </Fab>
            <Fab link="https://www.instagram.com/taxionspot/">
              <InstagramIcon />
            </Fab>
            <Fab link="https://www.youtube.com/channel/UC5sHNCp4Jis2mFlORwc3hNw?view_as=subscriber">
              <YouTubeIcon />
            </Fab>
          </div>
        </div>
      </div>
    );
  }
}

export default CopyRight;
